const animationData: {
  imageSrc: string
  imageSrcSet: string
  text: string
}[] = [
  {
    imageSrc: '/assets/images/mobile-animation/1@2x.webp',
    imageSrcSet:
      '/assets/images/mobile-animation/1.webp 600w, /assets/images/mobile-animation/1@2x.webp 2x, /assets/images/mobile-animation/1@2x.webp 1200w',
    text: 'hc_landing_screenshots_block_1_text',
  },
  {
    imageSrc: '/assets/images/mobile-animation/2@2x.webp',
    imageSrcSet:
      '/assets/images/mobile-animation/2.webp 600w, /assets/images/mobile-animation/2@2x.webp 2x, /assets/images/mobile-animation/2@2x.webp 1200w',
    text: 'hc_landing_screenshots_block_2_text',
  },
  {
    imageSrc: '/assets/images/mobile-animation/3@2x.webp',
    imageSrcSet:
      '/assets/images/mobile-animation/3.webp 600w, /assets/images/mobile-animation/3@2x.webp 2x, /assets/images/mobile-animation/3@2x.webp 1200w',
    text: 'hc_landing_screenshots_block_3_text',
  },
  {
    imageSrc: '/assets/images/mobile-animation/4@2x.webp',
    imageSrcSet:
      '/assets/images/mobile-animation/4.webp 600w, /assets/images/mobile-animation/4@2x.webp 2x, /assets/images/mobile-animation/4@2x.webp 1200w',
    text: 'hc_landing_screenshots_block_4_text',
  },
]

export { animationData }
