import { Text, useDeviceSize } from 'fitify-ui'
import { ImageTypes } from 'fitify-ui-landing/src/@types'
import {
  MobileAnimation,
  MobileAppFeatures,
} from 'fitify-ui-landing/src/components/blocks'
import Features from 'fitify-ui-landing/src/components/blocks/Features/Features'
import Header from 'fitify-ui-landing/src/components/blocks/Header/Header'
import HowItWorks from 'fitify-ui-landing/src/components/blocks/HowItWorks/HowItWorks'
import { StyledHowItWorksItemWithButton } from 'fitify-ui-landing/src/components/blocks/HowItWorks/HowItWorks.Styled'
import Mentions from 'fitify-ui-landing/src/components/blocks/Mentions/Mentions'
import Button from 'fitify-ui-landing/src/components/Button/Button'
import { size } from 'fitify-ui-landing/src/theme/breakpoints'
import { usePromoLink } from 'fitify-ui-landing/src/utils/link'
import { toBoolean } from 'fitify-utils/src/properties'
import { NextPage } from 'next'
import { useTranslation } from 'next-i18next'

import Faq from '@/components/blocks/Faq/Faq'
import TryIt from '@/components/blocks/TryIt/TryIt'
import Visual from '@/components/blocks/Visual/Visual'
import Layout from '@/components/layouts/Layout'
import { featuresData } from '@/data/features'
import { headerImages } from '@/data/header'
import { howItWorksData } from '@/data/how-it-works'
import { mentionsData } from '@/data/mentions'
import { getStaticPropsTranslations } from '@/utils/i18n'

import { animationData } from '../data/animations'

const Home: NextPage = () => {
  const { t } = useTranslation()

  const { windowWidth } = useDeviceSize()
  const isPhoneBreakpoint = windowWidth <= size.phone

  const isPriceBlockEnabled = toBoolean(
    process.env.NEXT_PUBLIC_PRICE_BLOCK_ENABLED
  )

  const promoLink = usePromoLink(process.env.NEXT_PUBLIC_ONBOARDING_URL)

  const visualItems: ImageTypes.Asset[] = [
    {
      width: 130,
      label: 'Calories',
      value: '459',
      icon: 'fire',
      iconColor: 'Red400',
      position: [10, undefined, undefined, windowWidth > 600 ? 24 : 10],
    },
    {
      width: 103,
      label: 'Exercises',
      value: '25',
      icon: 'balance',
      iconColor: 'Blue400',
      position: [40, undefined, undefined, windowWidth > 600 ? 35 : 30],
    },
    {
      width: 120,
      label: 'Heart Rate',
      value: '126',
      icon: 'heart',
      iconColor: 'Red400',
      position: [68, undefined, undefined, windowWidth > 600 ? 28 : 13],
    },
  ]

  return (
    <Layout
      seo={{
        title: t('hc_landing_page_title'),
        description: t('hc_landing_hero_subheadline_v1'),
      }}
    >
      <Header
        headline={'hc_landing_hero_headline_v1'}
        subheadline={'hc_landing_hero_subheadline_v1'}
        variant={'header-title-hc'}
        headerImages={headerImages}
        button={
          <Button variant={'primary-green'} href={promoLink} target="_blank">
            {t('hc_landing_cta_button_v1')}
          </Button>
        }
        shouldShowRating={false}
      />

      {isPhoneBreakpoint ? (
        <MobileAppFeatures features={animationData} />
      ) : (
        <MobileAnimation features={animationData} />
      )}

      <HowItWorks
        title={t('hc_landing_your_journey_with_fitify_headline')}
        items={howItWorksData}
      >
        <StyledHowItWorksItemWithButton>
          <a href={promoLink} target="_blank" rel="noreferrer">
            <Text
              as={'h3'}
              variant={'how-it-works-jump'}
              style={{ marginBottom: '16px' }}
            >
              {t('hc_landing_jump_right_in_title')}
            </Text>
            <Button variant={'primary-green'}>
              {t('hc_landing_cta_button_v1')}
            </Button>
          </a>
        </StyledHowItWorksItemWithButton>
      </HowItWorks>

      <Features items={featuresData} />

      <Mentions mentionsItems={mentionsData} />

      {isPriceBlockEnabled && <TryIt />}

      <Visual
        items={visualItems}
        imageSrc={'/assets/images/visuals/variant-1@2x.webp'}
        isScalingSize
        imageSrcSet={
          '/assets/images/visuals/variant-1.webp 600w, /assets/images/visuals/variant-1@2x.webp 2x, /assets/images/visuals/variant-1@2x.webp 1200w'
        }
      />
      <Faq />
    </Layout>
  )
}

export default Home

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await getStaticPropsTranslations(locale)),
    },
  }
}
