import { DataTypes } from 'fitify-ui-landing/src/@types'

const faqData: DataTypes.FaqItem[] = [
  {
    title: 'hc_pro_paywall_faqs_what_is_fitify_title',
    content: 'hc_pro_paywall_faqs_what_is_fitify_description_v1',
  },
  {
    title: 'hc_pro_paywall_faqs_do_i_need_gym_title',
    content: 'hc_pro_paywall_faqs_do_i_need_gym_description',
  },
  {
    title: 'hc_pro_paywall_faqs_can_i_try_different_trainers_title',
    content: 'hc_pro_paywall_faqs_can_i_try_different_trainers_description',
  },
  {
    title: 'hc_pro_paywall_faqs_do_i_need_equipment_title',
    content: 'hc_pro_paywall_faqs_do_i_need_equipment_description',
  },
  {
    title: 'hc_pro_paywall_faqs_progress_evaluation_title',
    content: 'hc_pro_paywall_faqs_progress_evaluation_description',
  },
  {
    title: 'hc_pro_paywall_faqs_additional_questions_title',
    content: 'hc_pro_paywall_faqs_additional_questions_description',
  },
  {
    title: 'hc_pro_paywall_faqs_cancelation_title',
    content: 'hc_pro_paywall_faqs_cancelation_description',
  },
]

export { faqData }
