import { Icon, Text } from 'fitify-ui'
import { ELEMENT_IDS } from 'fitify-ui-landing/src/@types/constants'
import Button from 'fitify-ui-landing/src/components/Button/Button'
import Container from 'fitify-ui-landing/src/components/Container/Container'
import Section from 'fitify-ui-landing/src/components/Section/Section'
import { redirectWithLocale } from 'fitify-ui-landing/src/utils/link'
import { useTranslation } from 'next-i18next'
import { Fade } from 'react-awesome-reveal'

import {
  StyledTryIt,
  StyledTryItItem,
  StyledTryItWarning,
} from './TryIt.Styled'

const TryIt = () => {
  const { t, i18n } = useTranslation()

  const onButtonClick = () => {
    const onboardingUrlEnv = process.env.NEXT_PUBLIC_ONBOARDING_URL
    redirectWithLocale(onboardingUrlEnv, i18n.language, '_blank')
  }

  return (
    <Section id={ELEMENT_IDS.pricing}>
      <Container>
        <Fade triggerOnce>
          <StyledTryIt>
            <StyledTryItItem>
              <Text
                as={'h2'}
                variant={'tryit-headline'}
                html={t('hc_landing_pricing_block_headline')}
              />
            </StyledTryItItem>
            <StyledTryItItem>
              <Text
                as={'p'}
                variant={'tryit-paragraph'}
                html={t('hc_landing_pricing_block_text_pt1')}
              />
              <br />
              <Text
                as={'p'}
                variant={'tryit-paragraph'}
                html={t('hc_landing_pricing_block_text_pt2')}
              />
              <Button
                variant={'primary-black'}
                style={{ marginTop: '24px', marginBottom: '16px' }}
                onClick={onButtonClick}
              >
                {t('hc_landing_pricing_block_cta')}
              </Button>
              <StyledTryItWarning>
                <Icon color={'Gray800'} name={'circle-warning'} />
                <Text color={'Gray800'} as={'p'} variant={'try-it-warning'}>
                  {t('hc_landing_pricing_block_cta_warning')}
                </Text>
              </StyledTryItWarning>
            </StyledTryItItem>
          </StyledTryIt>
        </Fade>
      </Container>
    </Section>
  )
}

export default TryIt
