import { colors, Icon, Text } from 'fitify-ui'
import { DataTypes } from 'fitify-ui-landing/src/@types'
import { motion, type Variants } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'

import {
  StyledFaqItemHead,
  StyledFaqItemToggler,
  StyledFaqList,
  StyledFaqListItem,
} from './Faq.Styled'

const FaqListItem = ({
  title,
  content,
}: {
  title: string
  content: string
}) => {
  const boxVariants: Variants = {
    active: {
      y: '0%',
      height: 'auto',
      opacity: 1,
      marginTop: '16px',
      transition: { y: { duration: 0.4 }, duration: 0.6 },
    },
    inactive: {
      height: 0,
      opacity: 0,
      marginTop: 0,
      transition: { opacity: { duration: 0.4 }, duration: 0.6 },
    },
  }

  const [isOpen, setIsOpen] = useState(false)

  return (
    <StyledFaqListItem>
      <StyledFaqItemHead onClick={() => setIsOpen(!isOpen)}>
        <Text
          as={'h3'}
          variant={'h3'}
          html={title}
          style={{
            maxWidth: '85%',
          }}
        />
        <StyledFaqItemToggler aria-label={'button'} isOpened={isOpen}>
          <Icon name={'plus'} />
        </StyledFaqItemToggler>
      </StyledFaqItemHead>
      <motion.div
        style={{ overflow: 'hidden' }}
        variants={boxVariants}
        initial="inactive"
        animate={isOpen ? 'active' : 'inactive'}
      >
        <Text
          as={'div'}
          style={{ fontSize: '16px', color: colors.Gray700 }}
          variant={'p'}
          html={content}
        />
      </motion.div>
    </StyledFaqListItem>
  )
}
const FaqList = ({ items }: { items: DataTypes.FaqItem[] }) => {
  const { t } = useTranslation()
  return (
    <StyledFaqList>
      {items.map((item, index) => {
        return (
          <FaqListItem
            key={index}
            title={t(item.title)}
            content={t(item.content)}
          />
        )
      })}
    </StyledFaqList>
  )
}

export default FaqList
