import { StyledIcon } from 'fitify-ui'
import { phone } from 'fitify-ui-landing/src/theme/breakpoints'
import styled, { css } from 'styled-components'

export const StyledTryIt = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  &:not(:first-child) {
    margin-top: 120px;
  }
  ${phone(css`
    flex-direction: column;
  `)};
`

export const StyledTryItItem = styled.div`
  &:first-child {
    width: 48%;
  }

  &:nth-child(2) {
    width: 52%;
  }

  em {
    white-space: nowrap;
  }

  ${phone(css`
    &:nth-child(1) {
      br {
        display: none;
      }
    }
    &:nth-child(n) {
      width: 100%;
    }
  `)};
`

export const StyledTryItWarning = styled.div`
  display: flex;
  align-items: center;

  ${StyledIcon} {
    margin-right: 8px;
  }
`
