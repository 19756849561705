import { StyledIcon, colors } from 'fitify-ui'
import { ButtonStyles } from 'fitify-ui-landing/src/components/Button/Button.Styles'
import { biggerPhone, phone } from 'fitify-ui-landing/src/theme/breakpoints'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const StyledFaq = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0;
  grid-row-gap: 0;
  ${phone(css`
    grid-template-columns: 1fr;
    grid-template-rows: 2fr;
    grid-column-gap: 0;
    grid-row-gap: 0;
    ${ButtonStyles} {
      width: 100%;
    }
  `)};

  ${ButtonStyles} {
    margin-top: 36px;
  }
  > div {
    &:first-child {
      padding-right: 100px;

      ${phone(css`
        margin-bottom: 16px;
        padding-right: 0;
      `)};
    }
  }
`

export const StyledFaqListItem = styled.li`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 40px;
  align-content: center;
`
export const StyledFaqList = styled.ul`
  display: flex;
  flex-direction: column;
  ${StyledFaqListItem} {
    padding: 16px 0;
    &:not(:first-child) {
      border-top: 1px solid ${colors.Gray200};
    }
    ${phone(css`
      &:last-child {
        @supports (-webkit-touch-callout: none) {
          margin-bottom: 60px;
        }
      }
    `)};
  }
`

export const StyledFaqItemToggler = styled.button<{ isOpened: boolean }>`
  flex-shrink: 0;
  padding: 0;
  background-color: ${colors.Gray200};
  width: 48px;
  height: 48px;
  border: none;
  cursor: pointer;
  border-radius: 100%;
  transition: transform 0.3s ease-out;
  ${StyledIcon} {
    color: ${colors.Black};
    transition: transform 0.3s ease-out;
    margin-top: 3px;

    ${(props) =>
      props.isOpened &&
      css`
        transform: rotate(45deg);
      `}
  }
  &:hover {
    opacity: 0.8;
    background: ${colors.Gray300};
  }

  ${biggerPhone(css`
    cursor: default;

    &:focus {
      outline: none;
    }
  `)}
`
export const StyledFaqItemHead = styled.div`
  width: 100%;
  min-height: 40px;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
  cursor: pointer;

  ${biggerPhone(css`
    cursor: default;

    &:focus {
      outline: none;
    }
  `)}
`
export const StyledFaqItemBody = styled(motion.div)``
